import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
    {
        name: 'notFound',
        path: '/:path(.*)+',
        redirect: {
            name: 'home',
        },
    },
    {
        name: 'home',
        path: '/home',
        component: () => import('./views/Home.vue'),
        meta: {
            title: '查询缴费' // 设置页面标题
        }
    },
    {
        name: 'confirm',
        path: '/confirm',
        component: () => import('./views/Confirm.vue'),
    },
    {
        name: 'result',
        path: '/result',
        component: () => import('./views/Result.vue'),
    },
];

const router = createRouter({
    routes,
    history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

export {router};
